import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const AlertTitle = makeShortcode("AlertTitle");
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Automated Certificate Management Environment (ACME) protocol radically simplifies TLS deployment.
With ACME, endpoints can obtain TLS certificates on their own, automatically.
`}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` works with any ACME-compliant (specifically, ACMEv2; RFC8555) client.`}</p>
    <h2>{`About this tutorial`}</h2>
    <ul>
      <li parentName="ul">{`Learn how to configure popular ACME clients to get certificates from `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Examples include copy/paste code blocks and specific commands for nginx, certbot, and more.`}</li>
      <li parentName="ul">{`When complete, you will have a fully functioning ACME configuration using a private certificate authority. `}</li>
      <li parentName="ul">{`Estimated effort: Reading time ~7 mins, Lab time ~20 to 60 mins. `}</li>
    </ul>
    <p>{`If you run into any issues, please let us know `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/certificates/discussions"
      }}>{`in GitHub Discussions`}</a>{` or on our `}<a parentName="p" {...{
        "href": "https://u.step.sm/discord"
      }}>{`Discord server`}</a>{`.`}</p>
    <h2>{`Requirements`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Open source -`}</strong>{` For `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{`, this tutorial assumes you have initialized and started up an instance using the steps in `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/getting-started"
        }}>{`Getting Started`}</a>{`. Additionally, you'll need to configure your CA with an ACME provisioner. Run `}<inlineCode parentName="li">{`step ca provisioner add acme --type ACME`}</inlineCode>{`, and restart your CA. `}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https:/prof.infra.smallstep.com/docs/certificate-manager/acme"
          }}>{`Smallstep Certificate Manager`}</a>{` -`}</strong>{` this tutorial assumes you have `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/certificate-manager/getting-started"
        }}>{`created a hosted or linked authority`}</a>{` and are running a local `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/registration-authorities/acme-for-certificate-manager"
        }}>{`ACME Registration Authority`}</a>{`. `}</li>
    </ul>
    <h2>{`Overview`}</h2>
    <p>{`Here are the most common configuration parameters for any ACME client:`}</p>
    <h3>{`Directory URL`}</h3>
    <p>{`Most ACME clients connect to Let’s Encrypt’s CA by default.
To connect to a private CA, you need to point the client your `}<a parentName="p" {...{
        "href": "https://tools.ietf.org/html/rfc8555#section-7.1.1"
      }}>{`ACME Directory URL`}</a>{`.`}</p>
    <p>{`A single instance of `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` can have multiple ACME provisioners, each with their own ACME Directory URL. The URL follows this form:`}</p>
    <p><inlineCode parentName="p">{`https://{ca-host}/acme/{provisioner-name}/directory`}</inlineCode></p>
    <p>{`For example, an ACME provisioner named `}<inlineCode parentName="p">{`ACME`}</inlineCode>{` on the host `}<inlineCode parentName="p">{`ca.internal`}</inlineCode>{` has the directory URL:`}</p>
    <p><inlineCode parentName="p">{`https://ca.internal/acme/ACME/directory`}</inlineCode></p>
    <h3>{`ACME challenge type`}</h3>
    <p>{`You'll need to select the ACME challenge type.`}</p>
    <h3>{`CA Certificate`}</h3>
    <p>{`Communication between an ACME client and server uses HTTPS.
Many clients will validate the server’s TLS certificate using the public root certificates in your system’s default `}<a parentName="p" {...{
        "href": "https://smallstep.com/blog/everything-pki/#trust-stores"
      }}>{`trust store`}</a>{`.
Some clients will let you pass a CA certificate bundle into the client.`}</p>
    <p>{`Clients will validate the server’s HTTPS certificate using the public root certificates in your system’s default trust store.
When you’re connecting to Let’s Encrypt, it’s a public certificate authority and its root certificate is already in your system’s default trust store.
Your internal root certificate isn’t, so HTTPS connections from ACME clients to `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` will fail.`}</p>
    <p>{`There are two ways to address this challenge.
Either:`}</p>
    <ul>
      <li parentName="ul">{`Explicitly configure your ACME client to trust `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{`’s root certificate, or`}</li>
      <li parentName="ul">{`Add the `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` root certificate to your system’s default trust store.`}</li>
    </ul>
    <p><inlineCode parentName="p">{`step`}</inlineCode>{` provides a helper command to do the latter:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step certificate install
`}</code></pre>
    <p>{`If you are using your certificate authority for TLS in production, explicitly configuring your ACME client to only trust your root certificate is a better option.
You will see how this method works with an example below.
You can find several other examples `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/tutorials/acme-protocol-acme-clients"
      }}>{`here`}</a>{`.`}</p>
    <p>{`If you are simulating Let’s Encrypt in pre-production, installing your root certificate is a more realistic simulation of production.
Once your root certificate is installed, no additional client configuration is necessary.`}</p>
    <Alert severity="warning" mdxType="Alert">
  <AlertTitle mdxType="AlertTitle">A Word of Caution</AlertTitle>
  Adding a root certificate to your system’s trust store is a global operation. Certificates issued by your CA will be trusted everywhere, including in many web browsers.
    </Alert>
    <h3>{`Renewal Period`}</h3>
    <p>{`With most ACME clients, you can configure how often you want to renew your certificates.
Choose a renewal period that is two-thirds of the entire certificate's lifetime, so that you'll have enough time to fix any renewal issues before it's too late.`}</p>
    <h2>{`Popular ACME Clients`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#certbot"
        }}>{`Certbot`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#acmesh"
        }}>{`acme.sh`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#win-acme"
        }}>{`win-acme`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#caddy-v2"
        }}>{`Caddy v2`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#nginx"
        }}>{`NGINX`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#apache"
        }}>{`Apache`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#node"
        }}>{`Node.js`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#golang"
        }}>{`Golang`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#python"
        }}>{`Python`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#traefik"
        }}>{`Traefik`}</a></li>
    </ul>
    <h3>{`Certbot`}</h3>
    <p><a parentName="p" {...{
        "href": "https://certbot.eff.org/"
      }}><inlineCode parentName="a">{`certbot`}</inlineCode></a>{` is the granddaddy of all ACME clients.
Built
and supported by `}<a parentName="p" {...{
        "href": "https://www.eff.org/"
      }}>{`the EFF`}</a>{`, it's the standard-bearer for
production-grade command-line ACME.`}</p>
    <p>{`To get a certificate from `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` using `}<inlineCode parentName="p">{`certbot`}</inlineCode>{` you need to:`}</p>
    <ol>
      <li parentName="ol">{`Point `}<inlineCode parentName="li">{`certbot`}</inlineCode>{` at your ACME directory URL using the `}<inlineCode parentName="li">{`--server`}</inlineCode>{` flag`}</li>
      <li parentName="ol">{`Tell `}<inlineCode parentName="li">{`certbot`}</inlineCode>{` to trust your root certificate using the `}<inlineCode parentName="li">{`REQUESTS_CA_BUNDLE`}</inlineCode>{` environment variable`}</li>
    </ol>
    <p>{`For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sudo REQUESTS_CA_BUNDLE=$(step path)/certs/root_ca.crt \\
    certbot certonly -n --standalone -d foo.internal \\
    --server https://ca.internal/acme/acme/directory
`}</code></pre>
    <p><inlineCode parentName="p">{`sudo`}</inlineCode>{` is required in `}<inlineCode parentName="p">{`certbot`}</inlineCode>{`'s `}<a parentName="p" {...{
        "href": "https://certbot.eff.org/docs/using.html#standalone"
      }}><em parentName="a">{`standalone`}</em>{`
mode`}</a>{` so it can listen on
port 80 to complete the `}<inlineCode parentName="p">{`http-01`}</inlineCode>{` challenge. If you already have a webserver
running you can use `}<a parentName="p" {...{
        "href": "https://certbot.eff.org/docs/using.html#webroot"
      }}><em parentName="a">{`webroot`}</em>{`
mode`}</a>{` instead. With the
`}<a parentName="p" {...{
        "href": "https://certbot.eff.org/docs/using.html#dns-plugins"
      }}>{`appropriate plugin`}</a>{`
`}<inlineCode parentName="p">{`certbot`}</inlineCode>{` also supports the `}<inlineCode parentName="p">{`dns-01`}</inlineCode>{` challenge for most popular DNS providers.
Deeper integrations with `}<a parentName="p" {...{
        "href": "https://certbot.eff.org/docs/using.html#nginx"
      }}>{`nginx`}</a>{`
and `}<a parentName="p" {...{
        "href": "https://certbot.eff.org/docs/using.html#apache"
      }}>{`apache`}</a>{` can even configure
your server to use HTTPS automatically (we'll set this up ourselves later). All
of this works with `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`.`}</p>
    <p>{`You can renew all of the certificates you've installed using `}<inlineCode parentName="p">{`cerbot`}</inlineCode>{` by running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sudo REQUESTS_CA_BUNDLE=$(step path)/certs/root_ca.crt certbot renew
`}</code></pre>
    <p>{`You can automate renewal with a simple `}<inlineCode parentName="p">{`cron`}</inlineCode>{` entry:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`*/15 * * * * root REQUESTS_CA_BUNDLE=$(step path)/certs/root_ca.crt certbot -q renew
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`certbot`}</inlineCode>{` packages for some Linux distributions will create a `}<inlineCode parentName="p">{`cron`}</inlineCode>{` entry
or `}<a parentName="p" {...{
        "href": "https://stevenwestmoreland.com/2017/11/renewing-certbot-certificates-using-a-systemd-timer.html"
      }}>{`systemd
timer`}</a>{`
like this for you. This entry won't work with `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` because it `}<a parentName="p" {...{
        "href": "https://github.com/certbot/certbot/issues/7170"
      }}>{`doesn't set
the `}<inlineCode parentName="a">{`REQUESTS_CA_BUNDLE`}</inlineCode>{` environment
variable`}</a>{`. You'll need to
manually tweak it to do so.`}</p>
    <p>{`More subtly, `}<inlineCode parentName="p">{`certbot`}</inlineCode>{`'s default renewal job is tuned for Let's Encrypt's 90
day certificate lifetimes: it's run every 12 hours, with actual renewals
occurring for certificates within 30 days of expiry. By default, `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`
issues certificates with `}<em parentName="p">{`much shorter`}</em>{` 24 hour lifetimes. The `}<inlineCode parentName="p">{`cron`}</inlineCode>{` entry
above accounts for this by running `}<inlineCode parentName="p">{`certbot renew`}</inlineCode>{` every 15 minutes. You'll
also want to configure your domain to only renew certificates when they're
within a few hours of expiry by adding a line like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`renew_before_expiry = 8 hours
`}</code></pre>
    <p>{`to the top of your renewal configuration (e.g., in `}<inlineCode parentName="p">{`/etc/letsencrypt/renewal/foo.internal.conf`}</inlineCode>{`).`}</p>
    <h3>{`acme.sh`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/Neilpang/acme.sh"
      }}>{`acme.sh`}</a>{` is another popular command-line ACME client. It's written completely in shell (`}<inlineCode parentName="p">{`bash`}</inlineCode>{`, `}<inlineCode parentName="p">{`dash`}</inlineCode>{`, and `}<inlineCode parentName="p">{`sh`}</inlineCode>{` compatible) with very few dependencies.`}</p>
    <p>{`To get a certificate from `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` using `}<inlineCode parentName="p">{`acme.sh`}</inlineCode>{` you need to:`}</p>
    <ol>
      <li parentName="ol">{`Point `}<inlineCode parentName="li">{`acme.sh`}</inlineCode>{` at your ACME directory URL using the `}<inlineCode parentName="li">{`--server`}</inlineCode>{` flag`}</li>
      <li parentName="ol">{`Tell `}<inlineCode parentName="li">{`acme.sh`}</inlineCode>{` to trust your root certificate using the `}<inlineCode parentName="li">{`--ca-bundle`}</inlineCode>{` flag`}</li>
    </ol>
    <p>{`For example:`}</p>
    <pre><code parentName="pre" {...{}}>{`sudo acme.sh --issue --standalone -d foo.internal \\
    --server https://ca.internal/acme/acme/directory \\
    --ca-bundle $(step path)/certs/root_ca.crt \\
    --fullchain-file foo.crt \\
    --key-file foo.key
`}</code></pre>
    <p>{`Like `}<inlineCode parentName="p">{`certbot`}</inlineCode>{`, `}<inlineCode parentName="p">{`acme.sh`}</inlineCode>{` can solve the `}<inlineCode parentName="p">{`http-01`}</inlineCode>{` challenge in `}<a parentName="p" {...{
        "href": "https://github.com/Neilpang/acme.sh#4-use-standalone-server-to-issue-cert"
      }}><em parentName="a">{`standalone`}</em>{` mode`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/Neilpang/acme.sh#2-just-issue-a-cert"
      }}><em parentName="a">{`webroot`}</em>{` mode`}</a>{`. It can also solve the `}<inlineCode parentName="p">{`dns-01`}</inlineCode>{` challenge for `}<a parentName="p" {...{
        "href": "https://github.com/Neilpang/acme.sh/wiki/dnsapi"
      }}>{`many DNS providers`}</a>{`.`}</p>
    <p>{`Renewals are slightly easier since `}<inlineCode parentName="p">{`acme.sh`}</inlineCode>{` remembers to use the right root certificate. It can also remember how long you'd like to wait before renewing a certificate. Unfortunately, the `}<a parentName="p" {...{
        "href": "https://github.com/Neilpang/acme.sh/issues/2422"
      }}>{`duration is specified in days`}</a>{` (via the `}<inlineCode parentName="p">{`--days`}</inlineCode>{` flag) which is too coarse for `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`'s default 24 hour certificate lifetimes. So the easiest way to schedule renewals with `}<inlineCode parentName="p">{`acme.sh`}</inlineCode>{` is to force them at a reasonable frequency, like every 8 hours, via cron:`}</p>
    <pre><code parentName="pre" {...{}}>{`0 */8 * * * root "/home/<user>/.acme.sh"/acme.sh --cron --home "/home/<user>/.acme.sh" --force > /dev/null
`}</code></pre>
    <h3>{`win-acme`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.win-acme.com/"
      }}>{`win-acme`}</a>{` (`}<inlineCode parentName="p">{`wacs.exe`}</inlineCode>{`) is a popular ACME client for Windows.`}</p>
    <p>{`To use `}<inlineCode parentName="p">{`win-acme`}</inlineCode>{` with `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`, you'll need to do the following:`}</p>
    <ul>
      <li parentName="ul">{`Add your root CA certificate (`}<inlineCode parentName="li">{`root_ca.crt`}</inlineCode>{`) to the Windows trust store.`}</li>
      <li parentName="ul">{`Change the ACMEv2 endpoint used by `}<inlineCode parentName="li">{`win-acme`}</inlineCode>{` (in the `}<inlineCode parentName="li">{`settings.json`}</inlineCode>{` file that comes with the program) to point to your CA's ACME provisioner (eg. `}<inlineCode parentName="li">{`https://ca.internal/acme/acme/`}</inlineCode>{`). Or pass the `}<inlineCode parentName="li">{`--baseuri`}</inlineCode>{` flag with your ACME provisioner's endpoint.`}</li>
      <li parentName="ul">{`We recommend using the `}<inlineCode parentName="li">{`tls-alpn-01`}</inlineCode>{` challenge type to prove ownership.`}</li>
    </ul>
    <h3>{`Caddy v2`}</h3>
    <p><a parentName="p" {...{
        "href": "https://caddyserver.com/"
      }}>{`Caddy`}</a>{` is an HTTP/2 web server with `}<em parentName="p">{`automatic HTTPS`}</em>{` powered by an integrated ACME client.
In addition to serving static websites, Caddy is commonly used as a TLS-terminating `}<a parentName="p" {...{
        "href": "https://caddyserver.com/docs/quick-starts/reverse-proxy"
      }}>{`API gateway proxy`}</a>{`.`}</p>
    <p>{`Caddy comes with its own ACME server and by default it will generate an internal CA and issue certificates to itself.
But, you can configure Caddy to use a local `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` instance to obtain certificates.`}</p>
    <p>{`Here's a `}<inlineCode parentName="p">{`Caddyfile`}</inlineCode>{` global config block.
Add this to the top of your `}<inlineCode parentName="p">{`Caddyfile`}</inlineCode>{` to get certificates from `}<inlineCode parentName="p">{`ca.internal`}</inlineCode>{` for all configured domains:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  email carl@smallstep.com
  acme_ca https://ca.internal/acme/acme/directory
  acme_ca_root <step path>/root_ca.crt
}
`}</code></pre>
    <p>{`Here's a `}<inlineCode parentName="p">{`Caddyfile`}</inlineCode>{` that will use `}<inlineCode parentName="p">{`ca.internal`}</inlineCode>{` only to get a certificate for `}<inlineCode parentName="p">{`foo.internal`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`foo.internal

root * /var/www
tls carl@smallstep.com {
  ca https://ca.internal/acme/acme/directory
  ca_root <step path>/certs/root_ca.crt
}
`}</code></pre>
    <p>{`Replace `}<inlineCode parentName="p">{`<step path>`}</inlineCode>{` with the output of the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/path/"
      }}><inlineCode parentName="a">{`step path`}</inlineCode></a>{` command.`}</p>
    <p>{`Now run `}<inlineCode parentName="p">{`caddy`}</inlineCode>{` to start serving HTTPS!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ sudo caddy start
`}</code></pre>
    <p>{`Check your work with curl:`}</p>
    <CodeBlock language="shell-session" copyText="curl https://foo.internal --cacert $(step path)/certs/root_ca.crt" mdxType="CodeBlock">
      {`$ curl https://foo.internal --cacert $(step path)/certs/root_ca.crt
 
Hello, TLS!`}
    </CodeBlock>
    <p>{`Caddy will automatically renew its certificates after `}{`⅔`}{` of the validity period elapses.`}</p>
    <h3>{`NGINX`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.nginx.com/"
      }}>{`Nginx`}</a>{` doesn’t support ACME natively, but you can use a command-line ACME client to get certificates for Nginx to use.`}</p>
    <p>{`Here’s an example `}<inlineCode parentName="p">{`nginx.conf`}</inlineCode>{` that runs Nginx in a common configuration where it terminates TLS and proxies to a back-end server listening on local loopback:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-nginx"
      }}>{`server {
  listen 443 ssl;
  server_name foo.internal;
  ssl_certificate /path/to/foo.crt;
  ssl_certificate_key /path/to/foo.key;
  location / {
    proxy_pass http://127.0.0.1:8000
  }
}
`}</code></pre>
    <p>{`With this code, you are telling Nginx to listen on port 443 using TLS, with a certificate and private key stored on disk.
`}<a parentName="p" {...{
        "href": "https://medium.com/@pentacent/nginx-and-lets-encrypt-with-docker-in-less-than-5-minutes-b4b8a60d3a71"
      }}>{`Other resources`}</a>{` provide a more thorough explanation of NGINX's various TLS configuration options.`}</p>
    <p>{`We can start an HTTP server using python and check our work with curl:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ echo "Hello TLS!" > index.html

$ python -m SimpleHTTPServer 8000 &

$ curl https://foo.internal --cacert $(step path)/certs/root_ca.crt

Hello TLS!
`}</code></pre>
    <p>{`Nginx only reads certificates once, only at startup.
When you renew the certificate on disk, Nginx won’t notice.
After each renewal you’ll need to run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`nginx -s reload
`}</code></pre>
    <p>{`You can use the `}<inlineCode parentName="p">{`--exec`}</inlineCode>{` flag on the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/renew"
      }}><inlineCode parentName="a">{`step ca renew`}</inlineCode></a>{` command to do this automatically:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca renew --daemon --exec "nginx -s reload" \\
    /path/to/foo.crt \\
    /path/to/foo.key
`}</code></pre>
    <p>{`If you’re using certbot, check out the `}<inlineCode parentName="p">{`--post-hook`}</inlineCode>{` flag to do the same thing.
If you’re using acme.sh, check out the `}<inlineCode parentName="p">{`--reloadcmd`}</inlineCode>{` flag.`}</p>
    <h3>{`Apache`}</h3>
    <p>{`Apache httpd has integrated ACME support via `}<a parentName="p" {...{
        "href": "https://github.com/icing/mod_md"
      }}>{`mod_md`}</a>{`.
You can deploy certificates to Apache in a way similar to what we did for Nginx.`}</p>
    <p>{`Here’s an example Apache configuration, using certificates issued by `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` through certbot:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-apacheconf"
      }}>{`<VirtualHost *:443>
  ServerName foo.internal
  DocumentRoot /home/mmalone/www
  SSLEngine on
  SSLCertificateFile /etc/letsencrypt/live/foo.internal/fullchain.pem
  SSLCertificateKeyFile /etc/letsencrypt/live/foo.internal/privkey.pem
</VirtualHost>
`}</code></pre>
    <p>{`Start Apache and check your work with curl:`}</p>
    <CodeBlock language="shell-session" copyText="curl --cacert $(step path)/certs/root_ca.crt https://foo.internal" mdxType="CodeBlock">
      {`$ curl --cacert $(step path)/certs/root_ca.crt https://foo.internal
 
Hello TLS`}
    </CodeBlock>
    <p>{`Like Nginx, Apache needs to be signaled after certificates are renewed by running the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`apachectl graceful
`}</code></pre>
    <h3>{`Node`}</h3>
    <p>{`Publish Lab’s `}<a parentName="p" {...{
        "href": "https://github.com/publishlab/node-acme-client"
      }}>{`acme-client`}</a>{` is an excellent ACMEv2 client written in Node.js.
Take a look at an example of how easy it is to obtain a certificate and serve HTTPS in JavaScript: `}<a parentName="p" {...{
        "href": "https://gist.github.com/mmalone/f3c33a2381ffa3d67e86c6d5ad3042c9"
      }}>{`https://gist.github.com/mmalone/f3c33a2381ffa3d67e86c6d5ad3042c9`}</a></p>
    <p>{`Most importantly, to make things work:`}</p>
    <ul>
      <li parentName="ul">{`Point the ACME client at your ACME directory URL`}</li>
      <li parentName="ul">{`Tell the ACME client to trust your CA by configuring the HTTP client to verify certificates using your root certificate`}</li>
    </ul>
    <p>{`To install dependencies and start the server run:`}</p>
    <CodeBlock language="shell-session" copyText="npm install node-acme-client && node acme.js" mdxType="CodeBlock">
      {`npm install node-acme-client
node acme.js
`}
    </CodeBlock>
    <p>{`Then check your work with curl:`}</p>
    <CodeBlock language="shell-session" copyText="curl https://foo.internal:11443 --cacert $(step path)/certs/root_ca.crt" mdxType="CodeBlock">
      {`$ curl https://foo.internal:11443 \\
    --cacert $(step path)/certs/root_ca.crt
 
Hello, TLS`}
    </CodeBlock>
    <p>{`This server supports optional client authentication using certificates and checks if the client authenticated in the handler:`}</p>
    <CodeBlock language="shell-session" copyText="curl https://foo.internal:11443 --cacert $(step path)/certs/root_ca.crt --cert mike.crt --key mike.key" mdxType="CodeBlock">
      {`$ curl https://foo.internal:11443 \\
    --cacert $(step path)/certs/root_ca.crt \\
    --cert mike.crt \\
    --key mike.key
 
Hello, mike@smallstep.com`}
    </CodeBlock>
    <h3>{`Golang`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/go-acme/lego"
      }}>{`lego`}</a>{` is an ACME client library written in Go.
You can use it to obtain a certificate from `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` programmatically.
You can find an example of this code here: `}<a parentName="p" {...{
        "href": "https://gist.github.com/mmalone/abce3c30df96972ed47f3298543be345"
      }}>{`https://gist.github.com/mmalone/abce3c30df96972ed47f3298543be345`}</a></p>
    <p>{`Essentially, the steps involved are:`}</p>
    <ul>
      <li parentName="ul">{`Point lego at your ACME directory URL by setting `}<inlineCode parentName="li">{`lego.Config.CADirUrl`}</inlineCode></li>
      <li parentName="ul">{`Tell lego to trust your CA by configuring an `}<inlineCode parentName="li">{`http.Client`}</inlineCode>{` that trusts your root certificate and telling lego to use it`}</li>
    </ul>
    <p>{`Fetch the required dependencies and start the server:`}</p>
    <CodeBlock language="shell-session" copyText="go get golang.org/x/net/http2 && go get github.com/go-acme/lego && go run acme.go" mdxType="CodeBlock">
      {`$ go get golang.org/x/net/http2
$ go get github.com/go-acme/lego
$ go run acme.go
`}
    </CodeBlock>
    <p>{`Then test with curl:`}</p>
    <CodeBlock language="shell-session" copyText="curl https://foo.internal:5443 --cacert $(step path)/certs/root_ca.crt" mdxType="CodeBlock">
      {`$ curl https://foo.internal:5443 \\
	--cacert $(step path)/certs/root_ca.crt
 
Hello, TLS!`}
    </CodeBlock>
    <p>{`The server is configured to verify client certificates if they are sent.
That means the server is configured to support mutual TLS.
The handler checks whether a client certificate was provided, and responds with a personalized greeting if one was.`}</p>
    <p>{`You can `}<a parentName="p" {...{
        "href": "https://smallstep.com/blog/easily-curl-services-secured-by-https-tls/"
      }}>{`get a client certificate from `}<inlineCode parentName="a">{`step-ca`}</inlineCode></a>{` using an OAuth/OIDC provisioner:`}</p>
    <CodeBlock language="shell-session" copyText="step ca certificate mike@example.com mike.crt mike.key" mdxType="CodeBlock">
      {`$ step ca certificate mike@example.com mike.crt mike.key
 
✔ Provisioner: Google (OIDC) [client: <redacted>.apps.googleusercontent.com]
✔ CA: https://ca.internal
✔ Certificate: mike.crt
✔ Private Key: mike.key`}
    </CodeBlock>
    <p>{`And test mutual TLS out with curl:`}</p>
    <CodeBlock language="shell-session" copyText="curl https://foo.internal:5443 --cacert $(step path)/certs/root_ca.crt --cert mike.crt --key mike.key" mdxType="CodeBlock">
      {`$ curl https://foo.internal:5443 \\
  --cacert $(step path)/certs/root_ca.crt \\
  --cert mike.crt \\
  --key mike.key
  
Hello, mike@example.com!`}
    </CodeBlock>
    <p>{`With a few tweaks to this code you can implement robust access control.`}</p>
    <p>{`There are other good options for programmatic ACME in Go.
The `}<a parentName="p" {...{
        "href": "https://github.com/mholt/certmagic"
      }}>{`certmagic`}</a>{` package builds on lego and offers higher level, easier to use abstractions.
The `}<a parentName="p" {...{
        "href": "https://godoc.org/golang.org/x/crypto/acme"
      }}>{`x/crypto/acme package`}</a>{` is lower level and offers more control, but it currently implements a pre-standardization draft version of ACME that doesn’t work with `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`.`}</p>
    <h3>{`Python`}</h3>
    <p><a parentName="p" {...{
        "href": "https://certbot.eff.org/"
      }}>{`certbot`}</a>{` is written in Python and exposes its acme module as a standalone package.
You can find an example of obtaining a certificate and serving HTTPS in Python here: `}<a parentName="p" {...{
        "href": "https://gist.github.com/mmalone/12f5422b2ec68e64e9d11eae0c6ca47d"
      }}>{`https://gist.github.com/mmalone/12f5422b2ec68e64e9d11eae0c6ca47d`}</a></p>
    <p>{`Make sure that you:`}</p>
    <ul>
      <li parentName="ul">{`Point the ACME client at your ACME Directory URL`}</li>
      <li parentName="ul">{`Tell the ACME client to trust your CA by configuring the injected HTTP client to verify certificates using your root certificate`}</li>
    </ul>
    <p>{`To install dependencies and start the server, run:`}</p>
    <CodeBlock language="shell" copyText="pip install acme && pip install pem && python https.py" mdxType="CodeBlock">
      {`pip install acme
pip install pem
python https.py
`}
    </CodeBlock>
    <p>{`Then check your work with curl:`}</p>
    <CodeBlock language="shell-session" copyText="curl https://foo.internal:10443 --cacert $(step path)/certs/root_ca.crt" mdxType="CodeBlock">
      {`$ curl https://foo.internal:10443 \\
	--cacert $(step path)/certs/root_ca.crt
 
Hello, TLS!`}
    </CodeBlock>
    <p>{`Like the Go example above, this server also supports mutual TLS and checks if the client authenticated in the handler:`}</p>
    <CodeBlock language="shell-session" copyText="curl https://foo.internal:10443 --cacert $(step path)/certs/root_ca.crt --cert mike.crt --key mike.key" mdxType="CodeBlock">
      {`$ curl https://foo.internal:10443 \\
  --cacert $(step path)/certs/root_ca.crt \\
  --cert mike.crt \\
  --key mike.key
 
Hello, mike@smallstep.com!`}
    </CodeBlock>
    <h3>{`Traefik`}</h3>
    <p><a parentName="p" {...{
        "href": "https://traefik.io/"
      }}>{`Traefik`}</a>{` is a modern reverse-proxy with integrated support for ACME. It's designed primarily to handle ingress for a compute cluster, dynamically routing traffic to microservices and web applications.`}</p>
    <h4>{`Traefik v2`}</h4>
    <p>{`It's easy to get a certificate from `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` in Traefik v2, using the `}<inlineCode parentName="p">{`tls-alpn-01`}</inlineCode>{` ACME challenge type.`}</p>
    <p>{`Most importantly, Traefik will need to trust your root CA certificate. Either use the `}<inlineCode parentName="p">{`LEGO_CA_CERTIFICATES`}</inlineCode>{` environment variable to provide the full path to your `}<inlineCode parentName="p">{`root_ca.crt`}</inlineCode>{` when running `}<inlineCode parentName="p">{`traefik`}</inlineCode>{`, or install your root certificate in your system's trust store by running `}<inlineCode parentName="p">{`step certificate install root_ca.crt`}</inlineCode>{`.`}</p>
    <p>{`In your Traefik static configuration, you'll need to add a `}<inlineCode parentName="p">{`certificatesResolvers`}</inlineCode>{` block:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ini"
      }}>{`[certificatesResolvers]
  [certificatesResolvers.myresolver]
    [certificatesResolvers.myresolver.acme]
      caServer = "https://step-ca.internal/acme/acme/directory"
      email = "carl@smallstep.com"
      storage = "acme.json"
      certificatesDuration = 24
      tlsChallenge = true
`}</code></pre>
    <p>{`Then, when you add routers to your dynamic configuration for HTTPS traffic, you need to set `}<inlineCode parentName="p">{`tls`}</inlineCode>{` and `}<inlineCode parentName="p">{`tls.certresolver`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ini"
      }}>{`[http]
  [http.routers]
    [http.routers.router1]
      ...
      [http.routers.router1.tls]
        certResolver = "myresolver"
`}</code></pre>
    <p>{`If you're running Traefik inside a Docker container, you can get your root CA certificate and add it to the container's trust store by running the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ step ca bootstrap --ca-url "\${CA_URL}" --fingerprint "\${CA_FINGERPRINT}" --install --force
$ update-ca-certificates
`}</code></pre>
    <h4>{`Traefik v1`}</h4>
    <p>{`To get a certificate from `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` to Traefik v1 you need to:`}</p>
    <ul>
      <li parentName="ul">{`Point Traefik at your ACME directory URL using the `}<inlineCode parentName="li">{`caServer`}</inlineCode>{` directive in your `}<a parentName="li" {...{
          "href": "https://docs.traefik.io/providers/file/"
        }}>{`configuration file`}</a></li>
      <li parentName="ul">{`Tell Traefik to trust your root certificate using the LEGO_CA_CERTIFICATES environment variable`}</li>
    </ul>
    <p>{`Here’s an example `}<inlineCode parentName="p">{`traefik.toml`}</inlineCode>{` file that configures Traefik to terminate TLS and proxy to a service listening on `}<em parentName="p">{`localhost`}</em>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ini"
      }}>{`defaultEntryPoints = ["http", "https"]
[entryPoints]
  [entryPoints.http]
  address = ":80"
  [entryPoints.https]
  address = ":443"
    [entryPoints.https.tls]
[acme]
storage = "acme.json"
caServer = "https://ca.internal/acme/acme/directory"
entryPoint = "https"
[acme.httpChallenge]
entryPoint = "http"
[[acme.domains]]
main = "foo.internal"
[file]
[frontends]
  [frontends.foo]
  backend = "foo"
[backends]
  [backends.foo]
    [backends.foo.servers.server0]
      url = "http://127.0.0.1:8000"
`}</code></pre>
    <p>{`Start Traefik by running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`LEGO_CA_CERTIFICATES=$(step path)/certs/root_ca.crt traefik
`}</code></pre>
    <p>{`Start an HTTP server for Traefik to proxy to, and test with curl:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ echo "Hello TLS!" > index.html
$ python -m SimpleHTTPServer 8000 &
$ curl https://foo.internal --cacert $(step path)/certs/root_ca.crt

Hello TLS!
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      